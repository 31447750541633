import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const OpenCultureDriversSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-0"
      style={{
        background: "linear-gradient(#cfe9f9, #cfe9f9)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          Open Culture
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Culture Drive Us
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          Our culture and values constantly accelerate our company, people, and vision in the right direction! These
          vital traits are injected into everything we do at Norhart from top to bottom.
        </h4>

        <h4 style={{ textAlign: "center", color: "#333333" }}>
          <i style={{ fontSize: "14px" }}>These are real stats from our 2023 Energage results.</i>
        </h4>

        <div className="d-flex justify-content-center mt-5 mb-5">
          <a
            className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
            href="/open-culture/norhart-open-culture-culture-drivers-04-2023.pdf"
            target="_blank"
            style={{
              backgroundColor: "#ffffff",
              border: "0px",
              color: "#333333",
            }}
          >
            View Report
          </a>
        </div>
      </div>

      <div class="container pb-5">
        <div class="container">
          <div class="row">
            <div class="col-9 engagement-bar ps-2 pt-2 pb-2" style={{ backgroundColor: "#bc5090", textAlign: "Left" }}>
              New ideas are encourgaged -10%
            </div>
            <div class="col-11 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#ff6361" }}>
              &nbsp;
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-9 engagement-bar ps-2 pt-2 pb-2" style={{ backgroundColor: "#bc5090", textAlign: "Left" }}>
              My manager helps me learn and grow -8%
            </div>
            <div class="col-11 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#ff6361" }}>
              &nbsp;
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-7 engagement-bar ps-2 pt-2 pb-2" style={{ backgroundColor: "#bc5090", textAlign: "Left" }}>
              We operate by strong values -15%
            </div>
            <div class="col-11 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#ff6361" }}>
              &nbsp;
            </div>
          </div>

          {/* Legend */}
          <div class="row mt-3">
            <div class="col-1 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#bc5090" }}>
              &nbsp;
            </div>
            <div
              class="col-11 engagement-bar pt-2 pb-2"
              style={{ backgroundColor: "transparent", textAlign: "left", color: "#365c70" }}
            >
              Norhart
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-1 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#ff6361" }}>
              &nbsp;
            </div>
            <div
              class="col-11 engagement-bar pt-2 pb-2"
              style={{ backgroundColor: "transparent", textAlign: "left", color: "#365c70" }}
            >
              Construction Top Workplace Companies
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/** export */
export default OpenCultureDriversSection
