import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import OpenCultureEmpoyeeEngagement from "../components/static-sections/OpenCultureEmpoyeeEngagementSection"
import OpenCultureCloud from "../components/static-sections/OpenCultureCloudSection"
import OpenCultureTransparency from "../components/static-sections/OpenCultureTransparencySection"
import OpenCultureDrivers from "../components/static-sections/OpenCultureDriversSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import KeynoteSection from "../components/dynamic-sections/KeynoteSection"

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import VideoIcon from "../../assets/video-icon.svg"
import StarReviewIcon from "../../assets/five-star-rating.svg"
import NorhartDarkCircleIcon from "../../assets/norhart-dark-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** props */
interface Props {
  data: any
}

/** const */
const OpenCulturePage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/open-culture/",
          name: "Open Culture | Norhart",
          image: `${config.siteMetadata.siteUrl}/open-culture/norhart-open-culture-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Open Culture | Norhart"
      description="Norhart is an award-winning construction and Property Management company where our culture of honesty and transparency is our way of life."
      keywords="norhart, award, minnesota, construction, property, management, values, growth, innovative, lean, amazing, exciting, forest lake, blaine, oakdale, minneapolis"
      imageTwitter={`${config.siteMetadata.siteUrl}/open-culture/norhart-open-culture-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/open-culture/norhart-open-culture-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Open Culture"
        subtitle="Complete Honesty & Transparency"
        imageTitle="Norhart Complete Honesty & Transparency"
        image="/open-culture/norhart-open-culture-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Awesome Culture and Values"
        tagLine="Our People Love Working at Norhart"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Culture"
        title="Culture Drives Our Company"
        subTitle={`"Great pay, good benefits and a culture unlike any other in the construction industry. – an employee in Framing Carpentry"`}
        image="/open-culture/norhart-open-culture-constuction.png"
        imageAlt="Norhart Support Team"
        textColor={colorPalette.textColor}
        asoEffect="fade-in"
      />

      <KeynoteSection
        backgroundColorOne="#1e4c80"
        backgroundColorTwo="#1e4c80"
        icon={VideoIcon}
        iconTitle="Announcement"
        title="Transparency Challenge"
        subtitle={`Watch Norhart's CEO, Mike Kaeding, issue a culture "Transparency Challenge" to every company in the world!`}
        colorPalette={colorPalette}
        posterFrame="/open-culture/video/culture-transparency-challenge-poster.png"
        keynoteUrl="/open-culture/video/culture-transparency-challenge.m4v"
      />

      <OpenCultureTransparency />

      <OpenCultureEmpoyeeEngagement />

      <HeroBottomSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"I know that everyone along side of me is working as hard as I am every day, without question. We are here for each other." - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
      />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Formula"
        title="People + Culture = Awards"
        subTitle="Hell Yes! Norhart's people, culture, and values are our most important differentiator! Winning the Top Workplaces award proves that fact. #highfive #kickass #work@norhart"
        image="/open-culture/norhart-open-culture-team404.png"
        imageAlt="Norhart Culture and Awards"
        textColor="#FFFFFF"
        asoEffect="fade-in"
        buttonName="View Our Awards"
        buttonUrl="/awards/"
      />

      <HeroBottomSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"If I won the lottery today. I'd still come to work tomorrow because the work is so fufilling!" - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <OpenCultureCloud />

      <HeroBottomSection
        backgroundColorOne="#EFEFEF"
        backgroundColorTwo="#EFEFEF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Reviews"
        title="Our Employees Say It Best"
        starRating="yes"
        subTitle={`"I get to work with amazing people everyday, and I am pushed to learn and improve myself in everytthing I do!" - Norhart Employee`}
        image="/support/norhart-employee-reviews-1.png"
        imageAlt="Norhart Employee Reviews"
        textColor={colorPalette.textColor}
        asoEffect="zoom-in"
      />

      <OpenCultureDrivers />

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart People"
        title="Great Hires Make A Company"
        subTitle="Recruiting the right talent sets us apart from the competition in our industry! This is the main reason Norhart stands out!"
        image="/open-culture/norhart-open-culture-recruiting.png"
        imageAlt="Great Hires Make A Company"
        textColor="#FFFFFF"
        asoEffect="fade-in"
        buttonName="Connect With Recruiting"
        buttonUrl="/recruiter-team/"
      />

      <AwardsSection
        header="Norhart Apartments"
        title="What Drives Us"
        subtitle="Our culture and values propel us forward. Always be respectful, results drive success, we love to cultivate innovation, and above all, we succeed together. We fail together. That's how we roll!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default OpenCulturePage
