import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const OpenCultureTransparencySection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-0"
      style={{
        background: "linear-gradient(#4488cf, #a2dbff)",
        color: "#FFFFFF",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#FFFFFF" }}
        >
          Norhart Transparancy
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#FFFFFF" }}>
          Totally Flippin' Transparent
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#FFFFFF", opacity: "0.8" }}>
          Yes! We are disclosing our employee's positive and negative engagement survey comments for the world to
          download and read! #ChallengeAccepted
        </h4>
      </div>

      <div className="d-flex justify-content-center mt-5 mb-5">
        <a
          className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
          href="/open-culture/pdf/norhart-energage-survey employee-comments-10-2023.pdf"
          target="_blank"
          style={{
            backgroundColor: "#ffffff",
            border: "0px",
            color: "#333333",
          }}
        >
          Download Engagement Report
        </a>
      </div>

      <div class="container mt-3 mb-0">
        <div class="row">
          <img src="/open-culture/norhart-open-culture-transparency.png" />
        </div>
      </div>
    </section>
  )
}
/** export */
export default OpenCultureTransparencySection
