import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const OpenCultureCloudSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-0"
      style={{
        background: "linear-gradient(#4488cf, #a2dbff)",
        color: "#FFFFFF",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#FFFFFF" }}
        >
          Norhart Culture
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#FFFFFF" }}>
          Our Culture
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#FFFFFF", opacity: "0.8" }}>
          We asked employees, "What three words describe the culture at Norhart?" These are the keywords that define our
          core values!
        </h4>

        <h4 style={{ textAlign: "center", color: "#FFFFFF" }}>
          <i style={{ fontSize: "14px" }}>These are real stats from our 2023 Energage results.</i>
        </h4>
      </div>

      <div class="container mt-3 mb-0">
        <div class="row">
          <img src="/open-culture/norhart-open-culture-word-cloud-2023.png" />
        </div>
      </div>
    </section>
  )
}
/** export */
export default OpenCultureCloudSection
