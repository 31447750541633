import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** svg */
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  colorPalette: ColorPalette
}

/** const */
const OpenCultureEmpoyeeEngagementSection: React.FC<Props> = (props) => {
  return (
    <section
      className="container-fluid pt-5 pb-0"
      style={{
        background: "linear-gradient(#cfe9f9, #cfe9f9)",
        color: "#365c70",
      }}
    >
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2">
          <NorhartDarkCircleIcon />
        </div>

        <div
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl"
          style={{ fontSize: "15px", textAlign: "center", color: "#333333" }}
        >
          Norhart Employees
        </div>
        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#365c70" }}>
          Employee Engagement
        </h3>
        <h4 className="contentHeroSubTitle" style={{ color: "#365c70", opacity: "0.8" }}>
          Yeass! 73% of Norhart employees scored our engagement as favorable. We are proud of our people, values, and
          culture.
        </h4>

        <h4 style={{ textAlign: "center", color: "#333333" }}>
          <i style={{ fontSize: "14px" }}>These are real stats from our 2023 Energage results.</i>
        </h4>
      </div>

      <div class="container">
        <div class="container">
          <div class="row">
            <div class="col-4 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#bc5090" }}>
              30%
            </div>
            <div class="col-2 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#ff6361" }}>
              15%
            </div>
            <div class="col-6 engagement-bar pt-2 pb-2" style={{ backgroundColor: "#ffa600" }}>
              56%
            </div>
          </div>
        </div>

        <div class="container d-none d-sm-block">
          <div class="row">
            <div class="col-4 engagement-text pt-2 pb-2" style={{ color: "#bc5090" }}>
              Unfavorable
            </div>
            <div class="col-2 engagement-text pt-2 pb-2" style={{ color: "#ff6361" }}>
              Neutral
            </div>
            <div class="col-6 engagement-text pt-2 pb-2" style={{ color: "#ffa600" }}>
              Favorable
            </div>
          </div>
        </div>
      </div>

      <div class="container mt-5">
        <div class="row">
          <img src="/benefits/norhart-you-are-our-most-valuable-asset.png" />
        </div>
      </div>
    </section>
  )
}
/** export */
export default OpenCultureEmpoyeeEngagementSection
